import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';

const AnyReactComponent = ({ text }) => <div>{text}</div>;
class GoogleMapStyle extends Component {
  static defaultProps = {
    center: {
      lat: 17.3850,
      lng: 78.4867
    },
    zoom: 11
  };

  renderMarkers(map, maps) {
    let marker = new maps.Marker({
      position: [17.3850, 78.4867],
      map,
      title: 'Third View Technologies'
    });
  }

  render() {
    return (
      // Important! Always set the container height explicitly
      <div className="google-map-style-1">
        <GoogleMapReact
          bootstrapURLKeys={{ key: 'AIzaSyBXWz0mBOO2gLDDvNCEa2sQtiy1zTpqVxw' }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
          // onGoogleApiLoaded={({map, maps}) => this.renderMarkers(map, maps)}
        >
          <AnyReactComponent
            lat={17.3850}
            lng={78.4867}
            text="Third View Technologies"
          />
        </GoogleMapReact>
      </div>
    );
  }
}

export default GoogleMapStyle;