import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";

const testimonialData = [
    {
        image: 'vyjayanthi_realty_venkat',
        name: 'Mr. Venkat Naidu Kolli',
        designation: 'Founder',
        location: 'Hyderabad, India', 
        description: '“I really liked the way Third View Technologies has delivered our website in a sprint time. They are client focused and fulfills the stakeholder requirements. A very enthusiastic and highly skilled team, who contributed to our vision of being online and accelerated our reach to customers. We are excited to continue our relationship with Third View Technologies for our next major updates.„',
        client: "Vyjayanthi Realty",
        clientURL: "https://vyjayanthirealty.com"
    },
    // {
    //     image: 'testimonial-02',
    //     name: 'Afsana Nila',
    //     designation: 'App Developer',
    //     location: 'Bangladesh', 
    //     description: '“Proin libero vel lorem dui lupus est aliquet luctus purus justo eget libero sed lorem.„',
      
    // },
    // {
    //     image: 'testimonial-03',
    //     name: 'Afanan Sifa',
    //     designation: 'Accounts Manager',
    //     location: 'Poland', 
    //     description: '“Proin libero vel lorem dui lupus est aliquet luctus purus justo eget libero sed lorem.„',
    // },
]


const TestimonialOne = ({column , teamStyle}) => {
    return (
        <div className="row row--15">
            {testimonialData.map((data, index) => (
                <div className={`${column}`} key={index}>
                    <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                        <div className={`rn-box-card ${teamStyle}`}>
                            <div className="inner" style={{"backgroundColor": "#000000"}}>
                                <figure className="thumbnail">
                                    <img src={`./images/testimonial/${data.image}.jpg`} alt="Vyjayanthi Realty Founder" />
                                </figure>
                                <figcaption className="content">
                                    <p className="description">{data.description}</p>
                                    <br/>
                                    <h2 className="title" style={{"color": "whitesmoke"}}>{data.name}</h2>
                                    <h6 className="subtitle" style={{"color": "white"}}>{data.designation}, <a style={{"textDecoration": "underline"}} href={data.clientURL} target="blank">{data.client}</a></h6>
                                </figcaption>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}

export default TestimonialOne;
