import React from 'react';
import SEO from "../../common/SEO";
import Layout from "../../common/Layout";
import BreadcrumbOne from "../../elements/breadcrumb/BreadcrumbOne";
import BlogProp from './itemProp/BlogProp';
import SectionTitle from '../../elements/sectionTitle/SectionTitle';


const BlogGridView = () => {
    return (
        <>
            <SEO title="Blogs | Third View Technologies" description="Whether you're looking for our perspective on the services we provide, latest architecture and development patterns, you'll find it here"
            keywords={"blogs, website, business, best practices, coding, architecture, development, project planning, cloud"}/>
            <Layout>
                {/* <BreadcrumbOne 
                    title="The Easiest Way To Create Website <br /> Quick Copy & Make site."
                /> */}
                <br/>
                <div className="main-content" style={{"backgroundColor": "whitesmoke"}}>
                    {/* Start Blog Area  */}
                    <div className="rn-blog-area rn-section-gap">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 mb--40">
                                    <SectionTitle
                                        textAlign = "text-center"
                                        radiusRounded = ""
                                        subtitle = "Blogs"
                                        title = "Whether you're looking for our perspective on the services we provide, latest architecture and development patterns, you'll find it here"
                                        description = ""
                                    />
                                </div>
                            </div>
                            <div className="row mt_dec--30">
                                <BlogProp column="col-lg-4 col-md-6 col-12 mt--30" StyleVarProp="box-card-style-default" />
                            </div>
                        </div>
                    </div>
                    {/* End Blog Area  */}
                </div>
            </Layout>
        </>
    )
}
export default BlogGridView;