import React from 'react';

const Darkmode = () => {
    const switchTheme = () => {
        document.querySelector('body').classList.toggle("light")
    }
    return (
        <>
        {switchTheme}
        </>
    )
}
export default Darkmode;