import PropTypes from "prop-types";
import React from 'react'


const SEO = ( {title, description, keywords} ) => {
    return (
        <>
            <meta charSet="utf-8" />
            <title>{title}</title>
            <meta name="robots" content="index, follow" />
            <meta name="description" content={description} />
            <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
            <meta name="keywords" content={keywords}></meta>
        </>
    )
}
SEO.propTypes = {
    title: PropTypes.string
};

export default SEO;


