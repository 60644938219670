import React from 'react';
import {Link} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";

const ServiceList = [
    {
        image: '/images/service/serviice-01.jpg',
        title: 'Website Design',
        description: 'We build websites that are responsive, fast and agile. We will be the backbone for your business to be online.'
    },
    {
        image: '/images/service/serviice-02.jpg',
        title: 'Enterprise Architecture & Solution Design',
        description: 'We assess & provide design solutions related to cloud, middleware & third party integrations.'
    },
    {
        image: '/images/service/serviice-03.jpg',
        title: 'Application Development',
        description: 'We provide technical expertise(Front end and Back end) to develop end to end solutions followed by support.'
    },
    // {
    //     image: '/images/service/serviice-04.jpg',
    //     title: 'Mobile Apps',
    //     description: 'Variations There are many of pass ages of Lorem Ipsum available the majority.'
    // }
]
const ServiceTwo = ({textAlign, cardStyle}) => {
    return (
        <div className="row row--15 service-wrapper">
              {ServiceList.map( (val , i) => (
                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                    <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                        <div className={`card-box ${cardStyle} ${textAlign}`}>
                            <div className="inner">
                                <div className="image">
                                    <Link to="#service">
                                        <img src={`${val.image}`} alt="card Images" />
                                    </Link>
                                </div>
                                <div className="content" style={{"textAlign": "justify"}}>
                                    <h4 className="title mb--20"><Link to="#service" dangerouslySetInnerHTML={{__html: val.title}}></Link></h4>
                                    <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{__html: val.description}}></p>
                                    <p className="description b1 color-gray mb--0">Please <a className="description b1 color-gray mb--0" href="/contact"><nobr>Contact us</nobr></a> for more information</p>
                                    {/* <Link className="btn-default btn-small btn-border" to="#service">Read More</Link> */}
                                </div>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}
export default ServiceTwo;