import React , {useState} from 'react';
import emailjs from 'emailjs-com';

const Result = () => {
    return (
        <p className="success-message">Your Message has been successfully sent. I will contact you soon.</p>
    )
}

function ContactForm({props , formStyle}) {
    const [ result,showresult ] = useState(false);

    const sendEmail = (e) => {
        e.preventDefault();
        emailjs
        .sendForm(
            'service_p4x3hv8', 
            'template_jgfr42f', 
            e.target, 
            'user_jrfTH2e0Ely35ZCVFdT9S'
        )
        .then((result) => {
            console.log(result.text);
            }, 
            (error) => {
                console.log(error.text);
            }
        );
        e.target.reset();
        showresult(true);
    };

    setTimeout(() => {
        showresult(false);
    }, 5000);

    return (
        <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSckZ1hD4x3YY_z4D7buOS3wGnVXcbneYfUunvV3XXv9fmXatg/viewform?embedded=true" width="100%" height="1115" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
    )
}
export default ContactForm;
