import React from 'react';
import HeaderOne from "./header/HeaderOne";
import HeaderTopNews from "./header/HeaderTopNews";
import FooterTwo from "./footer/FooterTwo";
import Copyright from "./footer/Copyright";
import Separator from '../elements/separator/Separator';
import FooterThree from './footer/FooterThree';
import FooterFour from './footer/FooterFour';
import FooterOne from './footer/FooterOne';
import HeaderTopBar from './header/HeaderTopBar';

const Layout = ({children}) => {
    return (
        <>
            <main className="page-wrapper">
                <div className="header-transparent-with-topbar">
                    {/* <HeaderTopBar />
                    <Separator/> */}
                    <HeaderOne btnStyle="btn-small btn-icon" HeaderSTyle="header-not-transparent" />
                </div>
                {children}
                <FooterThree />
                {/* <Copyright /> */}
            </main>
        </>
    )
}
export default Layout;
